import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import {
	Title,
	Center,
	Table,
	Stack,
	Button,
	Container,
	LoadingOverlay,
	Text,
	TitleSize,
	TitleOrder,
	MantineSpacing,
	StyleProp,
	Flex,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import slugify from 'slugify';
import ConfirmModal from '../modals/ConfirmModal';
import { plural } from 'pluralize';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { AbortableRequest } from '@dr-pam/common-components/Utils/FetchUtils';
import SearchInput, { SearchInputProps } from './SearchInput';
import FetchUtilsError from '@dr-pam/common-components/Errors/FetchUtilsError';
import { AbortType } from '@dr-pam/common-components/Utils/AbortUtils';

export type CrudTableColumn = {
	children?: React.ReactNode;
};

export type CrudTableProps<TList> = {
	className?: string;
	title?: {
		order?: TitleOrder;
		size?: TitleSize;
		children: ReactNode;
	};
	entityName: string;
	columns: (entity: TList) => CrudTableColumn[];
	headers: CrudTableColumn[];
	items?: TList[];
	itemClassName?: (entity: TList) => string;
	onAddClicked?: () => void;
	isLoading?: boolean;
	search?: SearchInputProps;
	margin?: StyleProp<MantineSpacing>;
	padding?: StyleProp<MantineSpacing>;
	additionalButtons?: ReactNode;
	addButtonText?: ReactNode;
};

export default function CrudTable<TList extends { id: string }>(props: CrudTableProps<TList>) {
	const {
		className,
		title,
		entityName,
		headers,
		columns,
		items,
		itemClassName,
		onAddClicked,
		isLoading,
		search,
		margin,
		padding,
		additionalButtons,
		addButtonText,
	} = props;

	return (
		<Stack className={className} w="100%">
			{title && (
				<Title order={title.order ?? 1} size={title.size ?? 'md'}>
					{title.children}
				</Title>
			)}
			{search || onAddClicked ? (
				<Flex justify="flex-end" gap="lg" align="flex-end">
					{search && <SearchInput {...search} />}
					{additionalButtons}
					{onAddClicked && <Button onClick={onAddClicked}>{addButtonText ?? <>Add {entityName}</>}</Button>}
				</Flex>
			) : null}
			<Container bg="var(--mantine-color-white)" fluid w="100%" m={margin} p={padding ?? 'xl'}>
				<LoadingOverlay visible={items === undefined || isLoading === true} overlayProps={{ blur: 2 }} />
				{items?.length === 0 ? (
					<Center h="100%">
						<Text fz="xl" ta="center">
							No {plural(entityName)}
						</Text>
					</Center>
				) : (
					<Table highlightOnHover>
						<Table.Thead>
							<Table.Tr>
								{headers.map((h, i) => (
									<Table.Th key={i}>{h.children}</Table.Th>
								))}
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{(items ?? []).map((item) => (
								<Table.Tr key={item.id} className={itemClassName?.(item)}>
									{columns(item).map((c, i) => (
										<Table.Td key={i}>{c.children}</Table.Td>
									))}
								</Table.Tr>
							))}
						</Table.Tbody>
					</Table>
				)}
			</Container>
		</Stack>
	);
}

export type CrudStateManager<TList, TSingle extends TList> = {
	isFetching: boolean;
	items?: TList[];
	setItems: Dispatch<SetStateAction<TList[] | undefined>>;
	add: (item: TSingle) => void;
	update: (item: TSingle) => void;
	delete: (item: TList | TSingle) => void;
	refetch: () => void;
};

export function useCrudState<TList extends { id: string }, TSingle extends TList>(
	fetcher?: () => AbortableRequest<TList[]> | null,
): CrudStateManager<TList, TSingle> {
	const [items, setItems] = useState<TList[] | undefined>(undefined);

	const [refetchKey, setRefetchKey] = useState(0);

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	useEffect(() => {
		if (!fetcher) {
			return;
		}

		const loader = addLoader();

		const abortableRequest = fetcher();

		abortableRequest?.response
			.then(setItems)
			.catch((err) => {
				// If there's no error, or it's an abort error (ie, not unknown), then we don't want to throw
				if (err === undefined || (err instanceof FetchUtilsError && err.type !== AbortType.UNKNOWN)) {
					return;
				}
				// Otherwise, re-throw
				throw err;
			})
			.finally(() => {
				removeLoader(loader);
			});

		return () => {
			abortableRequest?.abort();
			removeLoader(loader);
		};
	}, [addLoader, fetcher, removeLoader, setItems, refetchKey]);

	const addItem = (item: TSingle) => {
		setItems((items) => (items ? [...items, item] : undefined));
	};

	const updateItem = (item: TSingle) => {
		setItems((items) => (items ? items.map((i) => (i.id === item.id ? item : i)) : undefined));
	};

	const deleteItem = (item: TList | TSingle) => {
		setItems((items) => (items ? items.filter((i) => i.id !== item.id) : undefined));
	};

	const refetch = () => {
		setRefetchKey((key) => key + 1);
	};

	return {
		isFetching: isLoading,
		items,
		setItems,
		add: addItem,
		update: updateItem,
		delete: deleteItem,
		refetch,
	};
}

export type CrudModalProps = {
	modalId: string;
	onCancel: () => void;
};

export type CrudAddModalProps<TList, TSingle extends TList> = CrudModalProps & {
	onCreated: (entity: TSingle) => void;
};

export type CrudHandlerProps<TList, TSingle extends TList> = {
	entityName: string;
	crudState: CrudStateManager<TList, TSingle>;
	trapFocus?: boolean;
};

export type CrudAddHandlerProps<TList, TSingle extends TList> = CrudHandlerProps<TList, TSingle> & {
	modalFactory?: (props: CrudAddModalProps<TList, TSingle>) => React.ReactNode;
};

export function useCrudAddHandler<TList extends { id: string }, TSingle extends TList>(
	props: CrudAddHandlerProps<TList, TSingle>,
) {
	const modalId = 'add-' + slugify(props.entityName, { lower: true, strict: true, trim: true });

	const handleModalCancelled = () => {
		modals.close(modalId);
	};

	const handleCreated = (product: TSingle) => {
		props.crudState.add(product);
	};

	return () => {
		const modal = props.modalFactory?.({ modalId, onCancel: handleModalCancelled, onCreated: handleCreated });

		if (modal) {
			modals.open({
				modalId,
				closeOnClickOutside: false,
				closeOnEscape: false,
				withCloseButton: false,
				title: 'Add ' + props.entityName,
				children: modal,
				trapFocus: props.trapFocus ?? true,
			});
		}
	};
}

export type CrudEditModalProps<TList, TSingle extends TList> = CrudModalProps & {
	current: TList;
	onEdited: (entity: TSingle) => void;
};

export type CrudEditHandlerProps<TList, TSingle extends TList> = CrudHandlerProps<TList, TSingle> & {
	modalFactory?: (props: CrudEditModalProps<TList, TSingle>) => React.ReactNode;
};

export function useCrudEditHandler<TList extends { id: string }, TSingle extends TList>(
	props: CrudEditHandlerProps<TList, TSingle>,
) {
	const modalId = 'edit-' + slugify(props.entityName, { lower: true, strict: true, trim: true });

	const handleModalCancelled = () => {
		modals.close(modalId);
	};

	const handleEdited = (product: TSingle) => {
		props.crudState.update(product);
	};

	return (current: TList) => {
		const modal = props.modalFactory?.({
			modalId,
			current: current,
			onCancel: handleModalCancelled,
			onEdited: handleEdited,
		});

		if (modal) {
			modals.open({
				modalId,
				closeOnClickOutside: false,
				closeOnEscape: false,
				withCloseButton: false,
				title: 'Edit ' + props.entityName,
				children: modal,
				trapFocus: props.trapFocus ?? true,
			});
		}
	};
}

export type CrudDeleteHandlerProps<TList, TSingle extends TList> = CrudHandlerProps<TList, TSingle> & {
	delete?: (entity: TList) => Promise<void | unknown>;
	nameAccessor?: (entity: TList) => string;
};

export function useCrudDeleteHandler<TList extends { id: string }, TSingle extends TList>(
	props: CrudDeleteHandlerProps<TList, TSingle>,
) {
	const modalId = 'delete-' + slugify(props.entityName, { lower: true, strict: true, trim: true });

	return (current: TList) => {
		modals.open({
			title: 'Delete ' + props.entityName,
			modalId,
			children: (
				<ConfirmModal
					danger
					modalId={modalId}
					onConfirm={async () => {
						await props.delete?.(current);
						props.crudState.delete(current);
					}}
				>
					Are you sure you want to delete {props.nameAccessor ? `"${props.nameAccessor?.(current)}"` : 'this'}
					?
				</ConfirmModal>
			),
		});
	};
}

export type CrudStandardHandlers<TList, TSingle extends TList> = CrudDeleteHandlerProps<TList, TSingle> & {
	addModalFactory?: CrudAddHandlerProps<TList, TSingle>['modalFactory'];
	editModalFactory?: CrudEditHandlerProps<TList, TSingle>['modalFactory'];
};

export function useStandardCrudHandlers<TList extends { id: string }, TSingle extends TList>(
	props: CrudStandardHandlers<TList, TSingle>,
) {
	const addHandler = useCrudAddHandler({
		crudState: props.crudState,
		entityName: props.entityName,
		trapFocus: props.trapFocus,
		modalFactory: props.addModalFactory,
	});

	const editHandler = useCrudEditHandler({
		crudState: props.crudState,
		entityName: props.entityName,
		trapFocus: props.trapFocus,
		modalFactory: props.editModalFactory,
	});

	const deleteHandler = useCrudDeleteHandler({
		crudState: props.crudState,
		entityName: props.entityName,
		trapFocus: props.trapFocus,
		delete: props.delete,
		nameAccessor: props.nameAccessor,
	});

	return {
		addHandler,
		editHandler,
		deleteHandler,
	};
}
